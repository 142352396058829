export const REVIEWS_REQUEST = 'REVIEWS_REQUEST';
export const REVIEWS_SUCCESS = 'REVIEWS_SUCCESS';
export const REVIEWS_FAIL = 'REVIEWS_FAIL';
export const REVIEWS_RESET = 'REVIEWS_RESET';

export const ADD_REVIEW_LOADING_TRUE = 'ADD_REVIEW_LOADING_TRUE';
export const ADD_REVIEW_LOADING_FALSE = 'ADD_REVIEW_LOADING_FALSE';
export const ADD_REVIEW_SHOWMODAL_TRUE = 'ADD_REVIEW_SHOWMODAL_TRUE';
export const ADD_REVIEW_FAIL = 'ADD_REVIEW_FAIL';
export const ADD_REVIEW_RESET = 'ADD_REVIEW_RESET';

