export const CART_PRODUCT_DETAILS_REQUEST = 'CART_PRODUCT_DETAILS_REQUEST';
export const CART_PRODUCT_DETAILS_SUCCESS = 'CART_PRODUCT_DETAILS_SUCCESS';
export const CART_PRODUCT_DETAILS_FAIL = 'CART_PRODUCT_DETAILS_FAIL';
export const CART_PRODUCT_DETAILS_RESET = 'CART_PRODUCT_DETAILS_RESET';

export const CART_QTY_MESSAGE_REQUEST = 'CART_QTY_MESSAGE_REQUEST';
export const CART_QTY_MESSAGE_SUCCESS = 'CART_QTY_MESSAGE_SUCCESS';
export const CART_QTY_MESSAGE_FAIL = 'CART_QTY_MESSAGE_FAIL';
export const CART_QTY_MESSAGE_RESET = 'CART_QTY_MESSAGE_RESET';

export const CART_MOVED_MESSAGE_REQUEST = 'CART_MOVED_MESSAGE_REQUEST';
export const CART_MOVED_MESSAGE_SUCCESS = 'CART_MOVED_MESSAGE_SUCCESS';
export const CART_MOVED_MESSAGE_FAIL = 'CART_MOVED_MESSAGE_FAIL';
export const CART_MOVED_MESSAGE_RESET = 'CART_MOVED_MESSAGE_RESET';

export const CART_LOADING_TRUE = 'CART_LOADING_TRUE';
export const CART_LOADING_FALSE = 'CART_LOADING_FALSE';
export const CART_LOADING_RESET = 'CART_LOADING_RESET';






