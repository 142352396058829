export const CHECKOUT_BILLING_ADDRESS = 'CHECKOUT_BILLING_ADDRESS';
export const CHECKOUT_SHIPPING_ADDRESS = 'CHECKOUT_SHIPPING_ADDRESS';
export const CHECKOUT_PAYMENT_METHOD = 'CHECKOUT_PAYMENT_METHOD';
export const CHECKOUT_SUBTOTAL = 'CHECKOUT_SUBTOTAL';
export const CHECKOUT_SHIPPING_COST = 'CHECKOUT_SHIPPING_COST';
export const CHECKOUT_ITEM_TALLY = 'CHECKOUT_ITEM_TALLY';
export const CHECKOUT_CART_TOTAL = 'CHECKOUT_CART_TOTAL';
export const CHECKOUT_RESET = 'CHECKOUT_RESET';

export const ORDER_LOADING_TRUE = 'ORDER_LOADING_TRUE';
export const ORDER_LOADING_FALSE = 'ORDER_LOADING_FALSE';